const apiConfig = {
  //teste
  //baseUrl: 'https://gvmatriz.ddns.com.br:8078/SAVE/SOPORTWS/6e44215d0d554d8990b1f175082154a1',

  //produção
  baseUrl: 'https://gvpneus.servehttp.com:8075/SAVE/SOPORTWS/6e44215d0d554d8990b1f175082154a1',
  authorization: 'Basic YWRtaW46THVhQDI1MDA2NyE='
};

export { apiConfig }
